import classNames from "classnames";
import React from "react";
import type { PropsWithChildren } from "react";

type InputLabelProps = PropsWithChildren<{
    forInput?: string;
    text?: string;
    className?: string;
}>;

export default function InputLabel({
    forInput,
    text,
    className,
    children,
}: InputLabelProps) {
    return (
        <label
            htmlFor={forInput}
            className={classNames(
                "block font-bold mb-1 text-sm font-sans",
                className,
            )}
        >
            {text ?? children}
        </label>
    );
}
